import * as React from 'react'
import { Chart } from 'react-google-charts'

import _ from 'lodash'
import { LogRaw } from '../../../types'

void 0

type Props = {
  graphData: LogRaw[]
  visibles: string[]
  control?: boolean
  yDomain: [DomainValue, DomainValue]
  setSelectedLog: (log: LogRaw | null) => void
}
export type DomainValue = number | 'auto'
function Channel10LineGraphRange(props: Props) {
  const firstLog = _.first(props.graphData)
  const lastLog = _.last(props.graphData)
  const logs = React.useMemo(() => {
    const chs = props.visibles
    const logs = [
      ['time', ...chs],
      ...props.graphData.map(v => [
        new Date(v.timestamp),
        ...chs.map(k => v.fields[k]),
      ]),
    ]

    return logs
    // eslint-disable-next-line
  }, [
    firstLog && firstLog?.timestamp,
    lastLog && lastLog.timestamp,
    JSON.stringify(props.visibles),
  ])

  if (!firstLog || !lastLog) {
    return null
  }
  // 1 / 2を初期表示
  const visibleRangeEnd =
    firstLog.timestamp + (lastLog.timestamp - firstLog.timestamp) / 2

  console.log(props.graphData.length)
  return (
    <Chart
      width={'100%'}
      height={500}
      chartType="Line"
      loader={<div>Loading Chart</div>}
      data={logs}
      options={{
        chart: {
          chartArea: { height: '80%', width: '90%' },
        },
        // ui: {
        // },
        hAxis: {
          format: '0',
          maxValue: 200,
        },
        vAxis: {
          format: '0',
          viewWindowMode: 'explicit',
          viewWindow: {
            max: props.yDomain[0],
            min: props.yDomain[1],
          },
        },
      }}
      chartPackages={['corechart', 'controls']}
      controls={[
        {
          controlType: 'ChartRangeFilter',
          options: {
            filterColumnIndex: 0,
            ui: {
              chartType: 'LineChart',
              chartOptions: {
                chartArea: { width: '90%', height: '50%' },
                hAxis: { baselineColor: 'none' },
              },
            },
          },
          controlPosition: 'bottom',
          controlWrapperParams: {
            state: {
              range: {
                start: new Date(firstLog.timestamp),
                end: new Date(visibleRangeEnd),
              },
            },
          },
        },
      ]}
    />
  )
}

export default Channel10LineGraphRange
