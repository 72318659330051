import * as React from 'react'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers'

import MomentUtils from '@date-io/moment'
import moment, { Moment } from 'moment'

import useLocalStorage from 'react-use/lib/useLocalStorage'
import { mapValues } from 'lodash'
import { Button, Typography, CircularProgress } from '@material-ui/core'
import { LogRaw } from '../../types'
import { fetchLogs } from '../../state/Firebase/operations'
import Channel10Graph from './components/Channel10Graph'

type Props = {
  deviceId: string
  visibles: string[]
}

type Term = {
  startDate: number
  endDate: number
  startTs: number
  endTs: number
}

type TermMoment = {
  startTs: Moment
  endTs: Moment
}

function toMoment(term: Term): TermMoment {
  const { startDate, endDate, startTs, endTs } = mapValues(term, v => moment(v))

  return {
    startTs: startTs.set({
      year: startDate.year(),
      month: startDate.month(),
      date: startDate.date(),
    }),
    endTs: endTs.set({
      year: endDate.year(),
      month: endDate.month(),
      date: endDate.date(),
    }),
  }
}
const termValid = (t: Term) => t.startDate && t.endDate && t.startTs && t.endTs

function DeviceLogsHistory(props: Props) {
  const [term, setTerm] = useLocalStorage<Term>(`term`, {
    startDate: +moment(),
    endDate: +moment(),
    startTs: +moment().add(-15, 'm'),
    endTs: +moment(),
  })
  const [isEditing, setIsEditing] = useLocalStorage<boolean>(`isEditing`, true)
  const [logs, setLogs] = React.useState<LogRaw[] | undefined>(undefined)

  React.useEffect(() => {
    if (!termValid(term)) return

    if (isEditing) return

    const termM = toMoment(term)

    setLogs(undefined)
    let sync = true

    fetchLogs(props.deviceId, +termM.startTs, +termM.endTs).then(logs => {
      if (sync) setLogs(logs)
    })
    return () => {
      sync = false
    }
    // props.fetchRecent(Date.now() - range * 60 * 1000)
    // eslint-disable-next-line
  }, [props.deviceId, JSON.stringify(term), isEditing])

  if (!termValid(term)) {
    console.log('reset')
    setTerm({
      startDate: +moment(),
      endDate: +moment(),
      startTs: +moment().add(-15, 'm'),
      endTs: +moment(),
    })
    return null
  }
  const termM = toMoment(term)

  function handlePointChange(key: string, date: Moment | null) {
    if (!date || !date.isValid()) {
      return
    }
    setIsEditing(true)
    setTerm({ ...term, [key]: +date })
  }
  const handleDateChange = handlePointChange.bind(null, 'startDate')
  const handleEndDateChange = handlePointChange.bind(null, 'endDate')
  const handleStartChange = handlePointChange.bind(null, 'startTs')
  const handleEndChange = handlePointChange.bind(null, 'endTs')

  return (
    <div>
      <MuiPickersUtilsProvider locale={'ja'} utils={MomentUtils}>
        <KeyboardDatePicker
          label="先頭日付"
          labelFunc={date => (date ? date.format('YYYY/MM/DD') : '')}
          format="YYYY/MM/DD"
          value={termM.startTs}
          onChange={handleDateChange}
        />
        <KeyboardTimePicker
          label="先頭時刻"
          labelFunc={date => (date ? date.format('HH:mm') : '')}
          format="HH:mm"
          value={termM.startTs}
          onChange={handleStartChange}
        />
        <KeyboardDatePicker
          label="末尾日付"
          labelFunc={date => (date ? date.format('YYYY/MM/DD') : '')}
          format="YYYY/MM/DD"
          value={termM.endTs}
          onChange={handleEndDateChange}
        />
        <KeyboardTimePicker
          label="末尾時刻"
          labelFunc={date => (date ? date.format('HH:mm') : '')}
          format="HH:mm"
          value={termM.endTs}
          onChange={handleEndChange}
        />
        <Button
          variant="contained"
          disabled={!isEditing}
          onClick={() => {
            setIsEditing(false)
          }}
        >
          読み込む
        </Button>
      </MuiPickersUtilsProvider>

      {isEditing && (
        <Typography>「読み込む」ボタンを押してください </Typography>
      )}
      {termM.startTs.isValid() &&
        termM.endTs.isValid() &&
        !isEditing &&
        logs !== undefined && (
          <Channel10Graph
            deviceId={props.deviceId}
            visibles={props.visibles}
            graphData={logs}
          />
        )}
      {logs === undefined && <CircularProgress />}
    </div>
  )
}

export default DeviceLogsHistory
