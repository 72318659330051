import Typography from '@material-ui/core/Typography'
import * as React from 'react'
import { connect } from 'react-redux'
import useLocalStorage from 'react-use/lib/useLocalStorage'

import { Checkbox, Tab, FormControlLabel, Tabs } from '@material-ui/core'

import SwipeableViews from 'react-swipeable-views'

import { range, keys, pickBy, map } from 'lodash'
import DeviceLogsRecent from './DeviceLogsRecent'
import DeviceLogsHistory from './DeviceLogsHistory'

type Props = {
  deviceId: string
}
const intiialChannels = {}

range(1, 11).forEach(i => {
  intiialChannels[`ch${i.toString().padStart(2, '0')}`] = true
})

const DeviceLogs = (props: Props) => {
  const [graphTab, setGraphTab] = useLocalStorage<number>(`graph-tab`, 0)
  const [visibles, setVisibles] = useLocalStorage(
    `visible-device-${props.deviceId}`,
    intiialChannels,
  )

  return (
    <div>
      <Tabs
        value={graphTab}
        onChange={(e, v) => setGraphTab(v)}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="最新" />
        <Tab label="過去のログ" />
      </Tabs>
      <SwipeableViews
        slideStyle={{ overflow: 'hidden' }}
        index={graphTab}
        onChangeIndex={setGraphTab}
      >
        {graphTab === 0 ? (
          <DeviceLogsRecent
            deviceId={props.deviceId}
            visibles={keys(pickBy(visibles, Boolean))}
          />
        ) : (
          <div />
        )}
        {graphTab === 1 ? (
          <DeviceLogsHistory
            deviceId={props.deviceId}
            visibles={keys(pickBy(visibles, Boolean))}
          />
        ) : (
          <div />
        )}
      </SwipeableViews>
      <div style={{ display: 'flex' }}>
        <Typography style={{ padding: '10px' }} variant="body1">
          グラフ表示設定
        </Typography>
        <div>
          {map(visibles, (checked, ch) => (
            <FormControlLabel
              key={ch}
              label={ch}
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => {
                    setVisibles({ ...visibles, [ch]: !checked })
                  }}
                  value={ch}
                />
              }
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default DeviceLogs
