import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Typography } from '@material-ui/core'
import { getDevice } from '../../state/DeviceById/selectors'
import { Device, State } from '../../types'
import DeviceLogs from './DeviceLogs'

type Props = {
  deviceId: string
}

export const CardContainer = styled.div`
  min-width: 100px;
  border: solid 1px #dddddd;
  box-shadow: 0 1px 2px #888888;
  padding: 8px;
  margin-top: 8px;
  border-radius: 4px;
  margin-right: 2px;
  display: grid;
  grid-template-rows: max-content;
`

const RoadcellDevice = (props: Props) => {
  const device = useSelector<State, Device | undefined>(state =>
    getDevice(state, props.deviceId),
  )

  if (!device) {
    return (
      <Typography variant="body1">Loading: {`${props.deviceId}`}</Typography>
    )
  }
  return (
    <CardContainer>
      <section>
        <Typography variant="h5">{props.deviceId}</Typography>
        <DeviceLogs deviceId={props.deviceId} />
      </section>
    </CardContainer>
  )
}

export default RoadcellDevice
